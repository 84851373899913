body {
  margin: 0;
  font-family: 'Libre Baskerville', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjbQg61aAAAADUlEQVQYV2P4//8/IwAI/QL/+TZZdwAAAABJRU5ErkJggg=='),
    none !important;
}

#root {
  position: relative;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('fonts/librebaskerville-regular-webfont.woff2') format('woff2'),
    url('fonts/librebaskerville-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('fonts/librebaskerville-bold-webfont.woff2') format('woff2'),
    url('fonts/librebaskerville-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
