.container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  color: #000;
  transition: opacity 300ms ease;
  overflow: scroll;
}

.revealContainer {
  opacity: 1;
  transition: opacity 500ms 600ms ease;
}

.textContainer {
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

h1 {
  font-size: 3em;
  text-align: center;
  margin-bottom: 50px;
  padding: 0 40px;
}

p {
  font-size: 1.2em;
  padding: 0 40px;
}

@media (max-width: 550px) {
  h1 {
    font-size: 9vw;
    padding: 0 25px;
  }

  p {
    font-size: 4vw;
  }
}
