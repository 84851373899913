.question {
  position: absolute;
  color: #fff;
  font-size: 0.9em;
  bottom: 0;
  user-select: none;
  padding: 25px 35px;
  text-align: center;
  transition: opacity 500ms ease;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.question:hover .underline {
  transform: scaleX(1);
  transition: transform 150ms 100ms ease-out;
}

.underline {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0.0001);
  transition: transform 100ms 100ms ease-in;
}

.bg {
  position: absolute;
  bottom: 35px;
  left: 80px;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #fff;
  pointer-events: none;
  transform: translate(-50%, 50%) scale(0.00001);
  transform-origin: 50% 50%;
  transition: transform 500ms ease-in;
  will-change: transform;
}

.bgReveal {
  pointer-events: all;
  transform: translate(-50%, 50%) scale(2.8);
}
